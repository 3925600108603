import React from "react";
import PropTypes from "prop-types";
import FormInputCompany from "./FormInputCompany";
import { IoMdAddCircleOutline } from "react-icons/io";

const InviteFormEmployee = ({
  onSubmit,
  success,
  emails,
  addEmail,
  updateEmail,
}) => {
  return (
    <div className="w-48per bg-white rounded-card flex flex-col justify-between sm:hidden">
      <form onSubmit={onSubmit} className="flex flex-col justify-between">
        <div className="p-5">
          <h1 className="font-semibold text-2xl pb-2 max-w-sm md:max-w-none sm:text-xl">
            Fyll i epostadressen till den som du vill bjuda in.
          </h1>
          <p className="text-sm text-gray-500">
            En inbjudan kommer att skickas till epostadressen du anger nedan.
          </p>
        </div>
        <hr />
        {success ? (
          <div className="p-5">
            <h2 className="text-center text-blue-600 text-5xl">
              Inbjudan skickad!{" "}
            </h2>
            <p className="text-sm text-center py-5 text-green-800">
              En inbjudan har skickats till angiven epostadress
            </p>
          </div>
        ) : (
          <>
            <div className="flex items-start justify-between p-6">
              <div className="flex flex-col w-10/12">
                {emails.map((el, index) => (
                  <FormInputCompany
                    key={index}
                    idx={index}
                    id={"inputEmail"}
                    label={"Epostadress"}
                    onChange={updateEmail}
                    type={"email"}
                  />
                ))}
              </div>
              <div className="flex flex-col items-center justify-center cursor-pointer">
                <button
                  className="flex items-center flex-col"
                  type="button"
                  onClick={() => addEmail()}
                >
                  <IoMdAddCircleOutline className="w-7 h-7" />
                  <p className="text-sm">Lägg till</p>
                </button>
              </div>
            </div>
            <div className="w-full flex justify-center items-center py-10">
              <button
                type="submit"
                className="bg-blue-600 text-white px-6 rounded font-semibold py-2"
              >
                Skicka inbjudan
              </button>
            </div>
          </>
        )}
      </form>
    </div>
  );
};

InviteFormEmployee.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  success: PropTypes.bool.isRequired,
  emails: PropTypes.array.isRequired,
  addEmail: PropTypes.func.isRequired,
  updateEmail: PropTypes.func.isRequired,
};

export default InviteFormEmployee;
